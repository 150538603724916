import Vue from 'vue'
import Router from 'vue-router'
import { routes } from './routes'
import Layouts from '@/layouts/default'
import Login from '@/views/login'

Vue.use(Router)
const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      component: Login,
      name: 'login',
      hidden: true,
      redirect: '/login/login',
      children: [
        {
          path: '/login/login',
          component: () => import('@/views/login/loginItem'),
        },
        {
          path: '/login/regist',
          component: () => import('@/views/login/registItem'),
        },
        {
          path: '/login/forget',
          component: () => import('@/views/login/forgetItem'),
        }
      ]
    },
    {
      path: '/',
      redirect: '/home',
      meta: {
        title: '首页'
      },
      component: Layouts,
      children: routes
    }
  ]
})

export default router
