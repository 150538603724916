/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-26 17:06:24
 * @Module: 封禁管理api
 */
import request from '@/utils/request'
export default {
	//  封禁管理列表
  bannedListApi (params) {
    return request.post(`api/anchor/punishment`, params)
	}
}
