/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-26 18:13:15
 * @Module: 退会管理api
 */
import request from '@/utils/request'
export default {
	//退会管理列表
	anchorLeaveList(params) {
		return request.post(`api/anchor-leave/list`, params)
	}
}