/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-26 19:22:42
 * @Module: 邀约管理api
 */
import request from '@/utils/request'
export default {
	//  邀约管理列表
	inviteListApi(params) {
		return request.post(`api/invite/list`, params)
	},
	// 邀约管理列表 邀约状态筛选
	inviteStateOption(){
		return request.post(`api/invite/state-option`)
	},
	// 邀约管理列表 列表中的状态
	inviteStateList(){
		return request.post(`api/anchor/invite-state-list`)
	},
	// 更新邀约状态
	updateInviteState(params){
		return request.post(`api/invite/update/invite-state/${params.anchor_id}`,params)
	},
	// 更新快手ID
	updateTickId(params){
		return request.post(`api/invite/update/tick-id/${params.anchor_id}`,params)
	},
	//重发私信
	sendMessage(params){
		return request.post(`api/invite/send-message`,params)
	}
}