<template>
  <div class="custom-modal">
    <el-dialog :visible.sync="visible"
               :width="width"
               :show-close="false"
               :close-on-click-modal="false">
      <div class="custom-modal-title">{{title}}</div>
      <slot></slot>
      <div class="custom-modal-content"
           v-if="content">{{content}}</div>
      <div slot="footer"
           class="custom-modal-footer">
        <div @click="handleCancel"
             class="custom-modal-footer-cancel"
             v-if="showCancelBtn">取 消</div>
        <el-button type="primary"
                   @click="handleOk"
                   :loading="loading">{{defineBtn}}</el-button>
        <!-- <div @click="handleOk"
             class="custom-modal-footer-ok">{{defineBtn}}</div> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      defaule: true,
    },
    width: {
      type: String,
      default: "500px",
    },
    title: {
      type: String,
      default: "提示",
    },
    content: {
      type: String,
      default: "",
    },
    showCancelBtn: {
      type: Boolean,
      default: false
    },
    defineBtn: {
      type: String,
      default: "确 定"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {};
  },
  components: {},
  mounted () { },
  methods: {
    handleCancel () {
      this.$emit("handleCancel", false);
    },
    handleOk () {
      this.$emit("handleOk", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-modal /deep/ .el-dialog__header {
  padding: 0;
}
.custom-modal /deep/ .el-dialog__body {
  padding: 0;
}
.custom-modal /deep/ .el-dialog__footer {
  padding: 0;
}
.custom-modal /deep/ .el-dialog {
  border-radius: 4px;
  margin-top: auto !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.custom-modal {
  &-title {
    height: 58px;
    width: 100%;
    text-align: center;
    line-height: 58px;
    color: #333333;
    font-size: 18px;
  }
  &-content {
    height: 58px;
    padding: 0px 10px;
    text-align: center;
    font-size: 14px;
    line-height: 58px;
  }
  &-footer {
    height: 58px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    margin-bottom: 10px;
    &-ok {
      width: 85px;
      height: 32px;
      background: linear-gradient(270deg, #fe61a2 0%, #f39064 100%);
      border-radius: 2px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px 0px rgba(215, 77, 56, 0.3);
    }
    &-cancel {
      width: 85px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #e3e3e3;
      color: #333;
      margin-right: 10px;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>