<template>
  <el-container class="wrapper">
    <el-header>
      <Header @changeCollapse="changeCollapse" />
    </el-header>
    <el-container class="wrapper-container">
      <el-aside>
        <left-menu :isCollapse="isCollapse" />
        <div class="return"
             @click="returnClick">
          <i class="icon iconfont icon-fanhuiguanwangicon iconfont icon-fanhuiguanwang">
            <span v-show="!isCollapse">返回官网</span>
          </i>
        </div>
      </el-aside>
      <el-main>
        <breadcrumb v-show="showBreadcrumb"></breadcrumb>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import LeftMenu from "@/components/leftMenu";
import Header from "@/components/header";
import Breadcrumb from "@/components/breadcrumb";
export default {
  components: {
    LeftMenu,
    Header,
    Breadcrumb,
  },
  data () {
    return {
      isCollapse: false,
    };
  },
  methods: {
    returnClick () {
      window.location.href = 'http://www.wanghongtoutiao.cn'
    },
    changeCollapse () {
      this.isCollapse = !this.isCollapse;
    },
  },
  computed: {
    ...mapState({
      showBreadcrumb: (state) => state.layouts.showBreadcrumb,
    }),
  },
};
</script>

<style scoped lang="less">
.wrapper {
  height: 100vh;
  position: relative;
  .wrapper-container {
    overflow-y: hidden;
    position: relative;
    .el-aside {
      width: auto !important;
      max-width: 260px;
      overflow: hidden;
      position: relative;
      left: 0;
      top: 0;
      z-index: 2;
      // box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.05);
    }
    .el-main {
      position: relative;
      padding: 25px 20px 20px;
      overflow: auto;
      overflow-x: hidden;
      background-color: #f9f9f9;
      min-width: 1180px;
      .main-wrapper {
        padding: 10px 5px;
        height: 100%;
        background: #fff;
      }
      .layout-wrapper {
        height: 100%;
        background-color: #fff;
        background: #fff;
        position: relative;
      }
      .home {
        height: 100%;
      }
    }
  }
}
.wrapper /deep/ .el-menu {
  border-right: none;
}
/*滚动条样式*/
.el-main::-webkit-scrollbar {
  width: 8px;
  /*height: 4px;*/
}

.el-main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.el-main::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
/*滚动条样式*/
.wrapper-container::-webkit-scrollbar {
  // width: 8px;
  height: 8px;
}

.wrapper-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.wrapper-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.return {
  position: absolute;
  overflow: hidden;
  width: 100%;
  line-height: 48px;
  color: #333;
  font-size: 14px;
  background: #f9f9f9;
  bottom: 0;
  left: 0;
  text-align: center;
  span {
    cursor: pointer;
  }
  .iconfont {
    cursor: pointer;
    vertical-align: -1px;
    white-space: nowrap;
  }
  .icon-fanhuiguanwang:before {
    padding-right: 8px;
  }
}
</style>
