import homeapi from './homeapi'
import guildManageapi from './guildManageapi'
import agent from './agent'
import loginapi from './loginapi'
import anchorListapi from './anchorListapi'
import liveDataLsitapi from './liveDataLsitapi'
import bannedListapi from './bannedListapi'
import anchorLeaveListapi from './anchorLeaveListapi'
import inviteListapi from './inviteListapi'
import formComponentapi from './formComponentapi'
import staffList from './staffList'
import personalapi from './personalapi'
import finance from './finance'
import anchorMonitorapi from './anchorMonitorapi'

export default {
	...homeapi,
	...guildManageapi,
	...agent,
	...loginapi,
	...anchorListapi,
	...liveDataLsitapi,
	...bannedListapi,
	...anchorLeaveListapi,
	...inviteListapi,
	...formComponentapi,
	...staffList,
	...personalapi,
	...finance,
	...anchorMonitorapi
}
